import React from "react"
import "./style.css"
import Button from "../Button/Button"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

function H2WithNewlines({ children, className }) {
  return (
    <h2 className={className}>
      {children.split("\n")[0]}
      {children.split("\n").length > 1 &&
        children
          .split("\n")
          .slice(1)
          .map(sentence => (
            <>
              <br />
              {sentence}
            </>
          ))}
    </h2>
  )
}

function SectionR({ titre, description, textButton, photos, onButtonClick }) {
  return (
    <div className={"sectionMainR"}>
      <div className={"sectionTextesR"}>
        <h1 className={"sectionTitreR"}> {titre}</h1>
        <H2WithNewlines className={"sectionDescriptionR"}>
          {description}
        </H2WithNewlines>
        {textButton && (
          <Button text={textButton} theme={"dark"} onClick={onButtonClick} />
        )}
      </div>
      <GatsbyImage
        image={photos}
        alt="Plusieurs images de vidéos"
        className={"templateVideosR"}
        placeholder={"blur"}
      />
    </div>
  )
}

export default SectionR
