import React from "react"
import "./style.css"
import Button from "../Button/Button"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

function H2WithNewlines({ children, className }) {
  return (
    <h2 className={className}>
      {children.split("\n")[0]}
      {children.split("\n").length > 1 &&
        children
          .split("\n")
          .slice(1)
          .map(sentence => (
            <>
              <br />
              {sentence}
            </>
          ))}
    </h2>
  )
}

function SectionL({ titre, description, textButton, photos, onButtonClick }) {
  return (
    <div className={"sectionLmain"}>
      <GatsbyImage
        image={photos}
        alt="Plusieurs images de vidéos"
        className={"templateVideos"}
      />
      <div className={"sectionTitres"}>
        <h1 className={"sectionTitre"}>{titre}</h1>
        <H2WithNewlines className={"sectionDescription"}>
          {description}
        </H2WithNewlines>
        {textButton && (
          <Button text={textButton} theme={"dark"} onClick={onButtonClick} />
        )}
      </div>
    </div>
  )
}

export default SectionL
