import React from "react"
import "./index.css"
import PageHeader from "../components/PageHeader/PageHeader"
import SectionL from "../components/Section/SectionL"
import SectionR from "../components/SectionR/SectionR"
import Citation from "../components/Citation/Citation"
import PageFooter from "../components/PageFooter/PageFooter"
import {graphql, navigate, useStaticQuery} from "gatsby"
import {getImage} from "gatsby-plugin-image"
import Seo from "../components/seo"

const Home = () => {
  const {sanityHomepage} = useStaticQuery(query)

  const {
    citation: sanityCitation,
    VideosDescription: sanityVideosDescription,
    PhotosDescription: sanityPhotosDescription,
    ServicesDescription: sanityServicesDescription,
    AboutMeDescription: sanityAboutMeDescription,
    Header: sanityHeader
  } = sanityHomepage

  const sanityVideosThumbnail = getImage(sanityHomepage.VideosThumbnail.asset)
  const sanityPhotosThumbnail = getImage(sanityHomepage.PhotosThumbnail.asset)
  const sanityServicesThumbnail = getImage(sanityHomepage.ServicesThumbnail.asset)
  const sanityAboutMeThumbnail = getImage(sanityHomepage.AboutMeThumbnail.asset)

  return (
    <div style={{paddingTop: "100vh"}}>
      <Seo title="Accueil"/>
      <PageHeader
        titre={"Félix Brossard"}
        sousTitre={"Vidéaste Professionnel"}
        media={sanityHeader}
        controls
      />
      <div className={"citationHeader"}>
        <Citation text={sanityCitation}/>
      </div>
      <SectionL
        titre={"Vidéos"}
        description={sanityVideosDescription}
        textButton={"Voir mes vidéos"}
        photos={sanityVideosThumbnail}
        onButtonClick={() => {
          navigate("/galerie")
        }}
      />
      <SectionR
        titre={"Photos"}
        description={sanityPhotosDescription}
        textButton={"Voir mes photos"}
        photos={sanityPhotosThumbnail}
        onButtonClick={() => {
          navigate("/galerie")
        }}
      />
      <SectionL
        titre={"Services"}
        description={sanityServicesDescription}
        textButton={"Mes Services"}
        photos={sanityServicesThumbnail}
        onButtonClick={() => {
          navigate("/services")
        }}
      />
      <SectionR
        titre={"Qui suis-je ? "}
        description={sanityAboutMeDescription}
        textButton={"À propos"}
        photos={sanityAboutMeThumbnail}
        onButtonClick={() => {
          navigate("/a-propos")
        }}
      />
      <PageFooter/>
    </div>
  )
}

const query = graphql`
    query {
        sanityHomepage {
            citation
            Header {
                ...HeaderImage
                ...HeaderYoutubeVideo
            }
            VideosThumbnail {
                asset{
                    gatsbyImageData(width: 800, placeholder: BLURRED)
                }
            }
            VideosDescription
            PhotosThumbnail {
                asset{
                    gatsbyImageData(width: 800, placeholder: BLURRED)
                }
            }
            PhotosDescription
            ServicesThumbnail {
                asset{
                    gatsbyImageData(width: 800, placeholder: BLURRED)
                }
            }
            ServicesDescription
            AboutMeThumbnail {
                asset{
                    gatsbyImageData(width: 800, placeholder: BLURRED)
                }
            }
            AboutMeDescription
        }
    }
`

export const Head = () => <Seo title="Accueil"/>

export default Home
